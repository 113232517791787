import React, { useContext, useState } from "react"
import Img from "gatsby-image"
import Hero from "../configurable/Hero"
import Heading from "../configurable/Heading"
import Block from "../configurable/Block"
import Image from "../configurable/Image"
import Icon from "../olc-framework/Icon"
import HighlightBlock from "../configurable/HighlightBlock"
import Grid from "../configurable/Grid"
import { markdownNodesFilter } from "~/utils"
import Col9 from "../grid/Col9"
import Col4 from "../grid/Col4"
import Col8 from "../grid/Col8"
import Col11 from "../grid/Col11"
import Col6 from "../grid/Col6"
import Col7 from "../grid/Col7"
import Col5 from "../grid/Col5"
import Col12 from "../grid/Col12"
import TickList from "../configurable/TickList"
import BlockCTA from "../configurable/BlockCTA"
import "../configurable/ProductsAndWarrantiesBlock/styles.scss"
import "../configurable/BlockCTA/styles.scss"
import { CustomerTypeContext } from "~/providers/CustomerTypeProvider"
import Section from "../configurable/Section"
import "./aftersales.scss"
import { GetModal } from "../configurable/Modal"
import Col3 from "../grid/Col3"
import SectionNoAnim from "../configurable/SectionNoAnim"
import ImageRow from "../configurable/ImageRow"

const content = {
  call: {
    title: 'Call us',
    content: (
      <>
        <div className="modal_content_container">
          <div className="modal_copy_inverter" >

            <p>
              Select the right number for your query:
            </p>
          </div>
          <div className="modal_icon_container">
            <div>
              <Icon alias="phone" className="modal_icon" />
            </div>
            <div className="modal_icon_text" >
              <p><strong>Domestic customers: <br /> </strong><a style={{ textDecoration: "underline" }} href="tel:02039954422">020 3995 4422</a></p>
            </div>
          </div>
          <div className="modal_icon_container">
            <div>
              <Icon alias="phone" className="modal_icon" />
            </div>
            <div className="modal_icon_text" >
              <p><strong>Commercial enquiries: <br /> </strong><a style={{ textDecoration: "underline" }} href="tel:01920452175">01920 452 175</a></p>
            </div>
          </div>
          <div className="modal_icon_container">
            <div>
              <Icon alias="phone" className="modal_icon" />
            </div>
            <div className="modal_icon_text" >
              <p><strong>Solar Together London: <br /> </strong><a style={{ textDecoration: "underline" }} href="tel:02039898987">020 3989 8987</a></p>
            </div>
          </div>
          <div className="modal_icon_container">
            <div>
              <Icon alias="phone" className="modal_icon" />
            </div>
            <div className="modal_icon_text" >
              <p><strong>Solar Together Devon: <br /> </strong><a style={{ textDecoration: "underline" }} href="tel:02038669896">020 3866 9896</a></p>
            </div>
          </div>
        </div>
      </>
    )
  },
  email: {
    title: 'Email',
    content: (
      <>
        <div className="modal_content_container" >
          <div className="modal_copy_inverter" >

            <p>
              Select the right email for your query:
            </p>
          </div>
          <div className="modal_icon_container">
            <div>
              <Icon alias="email" className="modal_icon" />
            </div>
            <div className="modal_icon_text" >
              <p><strong>Domestic customers: <br /> </strong><a style={{ textDecoration: "underline" }} href="mailto:support@get-uk.com">support@get-uk.com</a></p>
            </div>
          </div>
          <div className="modal_icon_container">
            <div>
              <Icon alias="email" className="modal_icon" />
            </div>
            <div className="modal_icon_text" >
              <p><strong>Commercial enquiries: <br /> </strong><a style={{ textDecoration: "underline" }} href="mailto:commercial.enquiries@get-uk.com">commercial.enquiries@get-uk.com</a></p>
            </div>
          </div>
          <div className="modal_icon_container">
            <div>
              <Icon alias="email" className="modal_icon" />
            </div>
            <div className="modal_icon_text" >
              <p><strong>Solar Together: <br /> </strong><a style={{ textDecoration: "underline" }} href="mailto:solar.together@get-uk.com">solar.together@get-uk.com</a></p>
            </div>
          </div>
        </div>
      </>
    )
  },
  call_sales: {
    title: 'Call us',
    content: (
      <>
        <div className="modal_content_container">
          <div className="modal_copy_inverter" >

            <p>
              Select the right number for your query:
            </p>
          </div>
          <div className="modal_icon_container">
            <div>
              <Icon alias="phone" className="modal_icon" />
            </div>
            <div className="modal_icon_text" >
              <p><strong>Domestic customers: <br /> </strong><a style={{ textDecoration: "underline" }} href="tel:02039954422">020 3995 4422</a></p>
            </div>
          </div>
          <div className="modal_icon_container">
            <div>
              <Icon alias="phone" className="modal_icon" />
            </div>
            <div className="modal_icon_text" >
              <p><strong>Commercial enquiries: <br /> </strong><a style={{ textDecoration: "underline" }} href="tel:01920452175">01920 452 175</a></p>
            </div>
          </div>

        </div>
      </>
    )
  },
  email_sales: {
    title: 'Email',
    content: (
      <>
        <div className="modal_content_container" >
          <div className="modal_copy_inverter" >

            <p>
              Select the right email for your query:
            </p>
          </div>
          <div className="modal_icon_container">
            <div>
              <Icon alias="email" className="modal_icon" />
            </div>
            <div className="modal_icon_text" >
              <p><strong>Domestic customers: <br /> </strong><a style={{ textDecoration: "underline" }} href="mailto:domestic.sales@get-uk.com">domestic.sales@get-uk.com</a></p>
            </div>
          </div>
          <div className="modal_icon_container">
            <div>
              <Icon alias="email" className="modal_icon" />
            </div>
            <div className="modal_icon_text" >
              <p><strong>Commercial enquiries: <br /> </strong><a style={{ textDecoration: "underline" }} href="mailto:commercial.enquiries@get-uk.com">commercial.enquiries@get-uk.com</a></p>
            </div>
          </div>

        </div>
      </>
    )
  },
}

const Aftersales = ({ markdownNodes }) => {

  const [videoURL, setVideoURL] = useState('none');

  // START this makes it so the customer type is set dependng where the user came from

  const { customerType, setCustomerType } = useContext(CustomerTypeContext);

  const isBusiness = React.useMemo(() => customerType === "commercial", [customerType]);
  const isDomestic = React.useMemo(() => customerType === "domestic", [customerType]);
  const isSolarTogether = React.useMemo(() => customerType === "solartogether", [customerType]);

  // END this makes it so the customer type is set dependng where the user came from


  //START MODAL LOGIC

  let subtitle;

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f000';
  }

  const [modalContent, setModalContent] = React.useState(undefined);

  const [brandModalConfig, setBrandModalConfig] = React.useState(undefined);

  const closeModal = React.useCallback(() => {
    setModalContent(undefined);
  }, []);

  const onOpenModalFor = React.useCallback((name: string) => {
    setModalContent(content[name]);
  }, []);

  const getModalContent = () => {
    if (modalContent?.content) {
      return typeof modalContent?.content === 'function' ? modalContent?.content((brand) => {

        closeModal();
      }, brandModalConfig) : modalContent?.content
    }
    return modalContent?.content;
  }

  const getModalTitle = () => {
    if (modalContent?.title) {
      return typeof modalContent?.title === 'function' ? modalContent?.title(brandModalConfig) : modalContent?.title;
    }
    return '';
  }

  //END MODAL LOGIC

  return (
    <div>
      <GetModal
        show={Boolean(modalContent)}
        onClose={closeModal}
        title={getModalTitle()}
      >
        {getModalContent()}
      </GetModal>
      <Hero imageUrl="/images/banner_new_2.png" compact>
        <Heading level={1} underlined>
          After Sales Support
        </Heading>
      </Hero>
      <Block className="segBlock">
        <SectionNoAnim>
          <div className="container container--column illustrated-house-block">
            <Heading level={2} underlined>Contacting the Team</Heading>
            <p className="bold-formatting">
            At Green Energy Together, we have a dedicated team to support you through the life of your system. We recognise that customers’ lifestyles change overtime, and this may alter the way you wish to use your system. Additionally, from time to time there may be information you need relating to the operations of the portal or specific parts of your installation.
            </p>
            <ImageRow
              firstCol={9}
              secondCol={3}
              image={require('../../images/aftersales/Mask_Group.svg')}
              content={
                <>
                   <p className="bold-formatting">The after-sales support team have access to both manufacturers and in-house technical support. If you need to speak with a team member, you can contact us on one of the <strong>telephone numbers or email addresses</strong> below. Just click on one of the buttons </p>
                  <div className="mounting-ills row" >
                    <div className="mounting-ills__item">

                      <BlockCTA className="blue-btn" action={() => onOpenModalFor('call')}>
                        Call us
                      </BlockCTA>
                    </div>
                    <div className="mounting-ills__item">
                      <BlockCTA className="blue-btn" action={() => onOpenModalFor('email')}>
                        E-mail
                      </BlockCTA>
                    </div>
                  </div>
                </>
              }
            />
          </div>

        </SectionNoAnim>

        <SectionNoAnim>
          <div className="container container--column illustrated-house-block">
            <Heading level={2} underlined>Post Completion Activity</Heading>
            <p className="bold-formatting">
              After your installation is complete, the Green Energy Together team will ensure that all the appropriate paperwork is prepared and will furnish you with the following:
            </p>
            <div className="service__highlights" >
              <ImageRow
                firstCol={12}
                content={
                  <>
                    <div className="highlight">
                      <div className="highlight__icon">
                        <Icon alias="energy-transform" />
                      </div>
                      <div className="highlight__contents">
                        <Heading level={3}>DNO export MPAN</Heading>
                        <p className="bold-formatting">
                If your installation is a new micro-generator at your address, we will apply on your behalf for an export MPAN from the Distribution Network Operator, that owns the cabling to your property. This process can take <strong>up to 40+ days</strong> and is commonly referred as to as the DNO certification. This will then be forwarded to your email address.
              </p>
                      </div>
                    </div>
                  </>
                }
              />

              {/* <ImageRow
                firstCol={9}
                secondCol={3}
                image={require('../../images/aftersales/Group_313.svg')}
                content={
                  <>
                    <div className="highlight">
                      <div className="highlight__icon">
                        <Icon alias="energy-transform" />
                      </div>
                      <div className="highlight__contents">
                        <Heading level={3}>Handover Pack</Heading>
                        <p className="bold-formatting">Your handover pack<strong> is based around your installed system </strong>and will contain as fixed photographs of your installation for future reference. You will receive your handover Pack along with your proof of ownership once your invoice has been paid.  When you receive the Handover Pack, it will contain the following documents:</p>
                        <p className="bold-formatting" ><span style={{ color: "#70B33B" }}>• </span>The site-specific commissioning document completed by the team <span style={{ color: "#70B33B" }}>• </span>EIC certificate (Electrical Installation Certificate), MCS certificate (Microgeneration Certification Scheme) and Part P building regulations) <span style={{ color: "#70B33B" }}>• </span> System Schematic and Data Sheets of equipment used <span style={{ color: "#70B33B" }}>• </span> Start up and shut down procedure. <span style={{ color: "#70B33B" }}>• </span> Warranties</p>      
                        <p style={{ fontSize: ".7em", marginTop:"2em" }} >
                *With a few differences between Domestic and Commerical.
              </p>                 </div>
                        
                    </div>
                  </>
                }
              /> */}



            </div>
          </div>
        </SectionNoAnim>

        <SectionNoAnim>
          <div className="container container--column illustrated-house-block">
            <Heading level={2} underlined  >App Monitoring</Heading>
            <p className="bold-formatting">
              Once your installation is finished, our engineers get you setup for online system monitoring. Here you will be able to view what your Solar PV is generating, where you are getting our energy from i.e., your Solar Panels, Battery, or the grid. There are many features available on the app, and to help you understand what it all means, we have created videos to help you understand your monitoring system!</p>

            <ImageRow
              imageFirst={true}
              firstCol={3}
              secondCol={9}
              image={require('../../images/aftersales/Group_314.svg')}
              content={
                <>
                  <p className="bold-formatting">All you need to do is <strong>click below</strong> to select the system you have below:
                  </p>
                  <div className="brands row" style={{ padding: 0, marginBottom: "-20px" }} >
                    <button>
                      <img
                        src={require('../../images/brands/Group306.png')}
                        alt="my image"
                        onClick={() => setVideoURL('https://www.youtube.com/embed/zeb1ffxPHkc')}
                        style={{ maxHeight: 300 }}
                      />
                    </button>
                    <button>
                      <img
                        style={{ maxHeight: 300 }}
                        src={require('../../images/brands/Group304.png')}
                        alt="my image"
                        onClick={() => setVideoURL('https://www.youtube.com/embed/dM-Zz4cplIQ')}
                      />
                    </button>
                  </div>

                </>
              }
            />
          </div>

        </SectionNoAnim>

        <div className="row">
          <Col9 style={{ margin: "0 auto" }} >
            <div
              className="video"
              style={{
                position: "relative",
                paddingBottom: "56.25%" /* 16:9 */,
                height: 0,
                display: videoURL === 'none' ? 'none' : 'inherit'
              }}
            >
              <iframe
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  paddingBottom: "4em"
                }}
                src={`${videoURL}`}
                frameBorder="0"
              />
            </div>
          </Col9>
        </div>

        <SectionNoAnim>
          <div className="container container--column illustrated-house-block">
            <Heading level={2} underlined  >Wi-fi Set up</Heading>

            <p className="bold-formatting">Your Wi-Fi connection to the internet will be set up by the installation team before they leave your property. You will then receive; from the support team, details on how to access the application, including your login and password details. If for any reason your system loses connection, for example if your router goes off temporarily, you should first try resetting the router in which case the system should reconnect to the Wi-Fi automatically after 5 minutes. </p>

            <ImageRow
              imageFirst={true}
              firstCol={3}
              secondCol={9}
              image={require('../../images/aftersales/Group_315.svg')}
              content={
                <>
                  <p className="bold-formatting">If the system does not reconnect or if your change your Internet supplier, you’ll then need to reconnect your system. We have attached guidance documents on how to re-establish connection between your system & your Wi-Fi.</p>
                  <div className="brands row" style={{ padding: 0, marginBottom: "-20px" }} >
                    <button>
                      <img
                        src={require('../../images/brands/Group305.png')}
                        alt="my image"
                        style={{ maxHeight: 300 }}
                        onClick={() => window.open("https://get-uk-docs.netlify.app/sofar_wifi.pdf", "_blank")}
                      />

                    </button>
                    <button>
                      <img
                        src={require('../../images/brands/Group303.png')}
                        alt="my image"
                        style={{ maxHeight: 300 }}
                        onClick={() => window.open("https://get-uk-docs.netlify.app/foxess_wifi.pdf", "_blank")}
                      />

                    </button>
                  </div>

                </>
              }
            />
          </div>

        </SectionNoAnim>

        <SectionNoAnim>
          <div className="container container--column illustrated-house-block">
            <Heading level={2} underlined>System Alterations </Heading>
            <p className="bold-formatting">We acknowledge that peoples’ lifestyles change over time, and what was right for you initially may be improved by the addition of:</p>
            <ImageRow
              firstCol={9}
              secondCol={3}
              image={require('../../images/aftersales/Group_316.svg')}
              content={
                <>
                 
          <div className="row">
            <Col4><TickList>
              <li>Batteries </li>
            </TickList></Col4>
            <Col4><TickList>
              <li>EV Charger </li>
            </TickList></Col4>
            <Col4><TickList>
              <li>Panels </li>
            </TickList></Col4>
          </div>
          <p className="bold-formatting" style={{marginTop:"2em"}}>Our design team can quickly review your current installation and propose the correct changes needed to achieve your future. You should contact our sales team via one of these channels:</p>
          <div className="mounting-ills row" style={{marginTop:"0em"}} >
              <div className="mounting-ills__item" >

                <BlockCTA className="blue-btn" action={() => onOpenModalFor('call_sales')}>
                  Call us
                </BlockCTA>
              </div>
              <div className="mounting-ills__item">
                <BlockCTA className="blue-btn" action={() => onOpenModalFor('email_sales')}>
                  E-mail
                </BlockCTA>
              </div>
            </div>
                </>
              }
            />
          </div>

        </SectionNoAnim>







      </Block>
    </div>
  )
}

export default Aftersales
function getModalTitle(): string {
  throw new Error("Function not implemented.")
}

